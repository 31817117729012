import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EagleRiderClub = (props) => (
  <SvgIcon
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.23271 10.6246L7.99842 14.5214L14.7641 10.6246V1.45341H1.23271V10.6246ZM7.99842 15.9464L0 11.3277V0.220703H16V11.3277L7.99842 15.9464Z" fill="black" />
    <path d="M5.42589 5.62447C4.79535 5.5425 4.84264 5.34703 4.84264 5.34703L6.52619 5.05698C6.52619 5.05698 5.66235 5.65599 5.42589 5.62447ZM13.8058 8.46191C12.4754 7.99215 10.5081 6.49146 10.5081 6.49146C10.9031 6.49224 11.2978 6.47014 11.6903 6.42526C11.0354 6.12593 10.4032 5.77926 9.7987 5.38801C8.99161 4.81737 8.79614 4.38545 7.62648 4.24358C7.42586 4.21554 7.22371 4.19975 7.02116 4.19629C6.09489 4.20254 5.20099 4.53776 4.499 5.1421C4.29407 5.31865 4.15535 5.41954 4.15535 5.41954C3.68884 5.44874 3.22903 5.54538 2.79023 5.70644C2.67548 5.73678 2.56869 5.79168 2.47724 5.86734C2.38579 5.94301 2.31187 6.03763 2.26057 6.14466C2.11555 6.44417 2.30471 7.22604 2.31102 7.21974C3.5185 6.4757 5.6655 7.21974 5.81052 7.49718C5.60875 6.96122 4.78905 6.40949 4.78905 6.40949C4.78905 6.40949 6.05013 6.69639 6.3654 7.81245C6.68067 8.92851 5.22727 9.42033 5.14215 9.46447C5.84847 9.37799 6.54868 9.24743 7.2387 9.07353C7.22878 9.23406 7.20451 9.39338 7.16619 9.54959C7.48146 9.39826 8.19712 9.04831 8.19712 9.04831C8.19712 9.04831 10.032 9.39511 10.7477 9.4487C10.3483 9.20714 9.98699 8.90751 9.67575 8.55964C9.67575 8.55964 11.9173 9.05777 12.6708 9.19018C12.2926 8.94012 11.9536 8.63517 11.6651 8.28535C12.3686 8.40893 13.0822 8.46593 13.7963 8.4556" fill="black" />

  </SvgIcon>
);

export default EagleRiderClub;
