import React, { useMemo } from 'react';
import {
  makeStyles, Card, CardContent, Grid, Box, ButtonBase,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PersonIcon from '@material-ui/icons/Person';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import HeightIcon from 'components/shared/icons/HeightIcon';
import WeightIcon from 'components/shared/icons/WeightIcon';
import EagleRiderClub from 'components/shared/icons/EagleRiderClub';
import CCIcon from 'components/shared/icons/CCIcon';
import { withTranslation } from 'config/i18n';
import Image from 'next/image';
import bluer from '../../../public/imgs/1x1.png';

const useStyles = makeStyles((theme) => ({
  cardBtn: {
    display: 'block',
    width: '100%',
    borderRadius: 4,
    height: '100%',
  },
  root: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    margin: '0 0 0',
    cursor: 'pointer',
    position: 'relative',
    height: '100%',
    '& img[data-src].lazyLoading': {
      transform: 'none',
    },
  },
  cardContentRootOverride: {
    position: 'relative',
    // display: 'flex',
    paddingBottom: `${theme.spacing(2.5)} !important`,
    paddingTop: theme.spacing(3),
    height: `calc(100% - ${theme.spacing(23.375)})`,
  },
  // card header
  cardHeader: {
    display: 'flex',
    paddingRight: '0',
    flex: '1 1 auto',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  // labels
  bcShared: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.dark,
    fontFamily: "'Acumin Pro', sans-serif",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '300',
    '& svg': {
      marginRight: theme.spacing(0.5),
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    '& b': {
      marginRight: theme.spacing(0.25),
    },
  },
  bcOwner: {
    alignItems: 'flex-start',
    '& span': {
      lineHeight: theme.spacing(2),
    },
  },
  bcOwnerName: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(0.25),
  },
  unit: {
    whiteSpace: 'nowrap',
  },
  bcTitle: {
    color: theme.palette.secondary.dark,
    textAlign: 'left',
  },
  make: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '500',
    margin: '0',
    fontFamily: "'Acumin Pro', sans-serif",
  },
  model: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '500',
    fontFamily: "'DrukText', sans-serif",
    wordBreak: 'break-word',
    paddingRight: theme.spacing(0.625),
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    marginTop: 0,
  },
  bcLocation: {
    marginBottom: theme.spacing(0.5),
  },
  bcLocationCol: {
    paddingRight: theme.spacing(0.25),
    flex: '1',
  },
  bcMeasuresCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: '0',
    flex: '0 0 auto',
    maxWidth: '40%',
    '& $bcShared': {
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: '0',
      },
    },
  },
  bcMedia: {
    position: 'relative',
    height: theme.spacing(23.375),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  rentalImg: {
    minHeight: '100%',
    width: '100%',
  },
  rentalImgUser: {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
  },

  bcContentLabel: {
    position: 'absolute',
    right: '0',
    top: '0',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(10),
    '& svg': {
      width: theme.spacing(1.5),
      marginRight: theme.spacing(1),
    },
  },
  bcLabel: {
    padding: theme.spacing(0, 0.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bcAvailability: {
    backgroundColor: '#e65444',
    color: theme.palette.common.white,
  },
  bcValueDeal: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  bcSoldOut: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
    textTransform: 'uppercase',
  },
  bcTentative: {
    backgroundColor: theme.palette.common.fogWhite,
    color: theme.palette.primary.contrastText,
  },
  // content
  cardContent: {
    margin: theme.spacing(0, -2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  gridItem: {
    paddingLeft: theme.spacing(1.5),
  },
  cardSkeletonWrap: {
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
  },
  nPrices: {
    marginLeft: theme.spacing(1),
    flexShrink: '0',
  },
  nDailyPrice: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey['900'],
    color: theme.palette.common.white,
    textAlign: 'center',
    width: '100%',
    minWidth: theme.spacing(13.75),

    '& .amount': {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(18),
      marginRight: theme.spacing(0.5),
    },
  },
  nHourlyPrice: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.secondary.dark,
    textAlign: 'center',
    width: '100%',
    minWidth: theme.spacing(13.75),

    '& .amount': {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(0.5),
    },
  },
}));

const BikeCard = ({
  t, bike, onBikeCardClick, priorityImg,
}) => {
  const classes = useStyles();
  const renter = useMemo(() => ({
    icon: bike?.owner ? <PersonIcon /> : <EagleRiderClub />,
    name: bike?.renter?.name || bike?.owner?.display_name,
    translationKey: bike?.owner ? 'fe_bike_card:rented_by' : 'fe_bike_card:by_er',
  }), [bike]);
  return (
    <>
      {bike
        ? (
          <ButtonBase
            className={classes.cardBtn}
            onClick={bike.url ? () => onBikeCardClick(bike.url) : bike.onClick || null}
          >
            <Card
              className={`${classes.root} js-vehicleContainer`}
              data-renter-id={bike.renter ? bike.renter.id : ''}
              data-renter-type={bike.renter ? bike.renter.type : ''}
            >
              <div className={`${classes.bcMedia} js-vehicle-image-container`}>
                <Image
                // eslint-disable-next-line no-nested-ternary
                  className={`${classes.rentalImg} ${bike.renter === 'user' ? classes.rentalImgUser : ''}`}
                  src={bike?.image_path ? bike.image_path : '/imgs/moto-placeholder.png'}
                  alt={bike.vehicle_model.name}
                  placeholder="blur"
                  blurDataURL={bluer.src}
                  layout="fill"
                  objectFit={bike?.owner ? 'cover' : 'contain'}
                  unoptimized
                  lazyBoundary="50px"
                  priority={priorityImg}
                />

              </div>
              <CardContent
                className="js-vehicle-info-container"
                classes={{ root: classes.cardContentRootOverride }}
              >
                <input type="hidden" value={bike.id} className="js-vehicle-id" />
                <input type="hidden" value={bike.path} className="js-vehicle-path" />

                <div className={classes.bcContentLabel}>
                  {bike.availableCount && (
                  <div className={`${classes.bcAvailability} ${classes.bcLabel}`}>
                    <WhatshotIcon />
                    <span
                    // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t('fe_bike_card:availability', {
                          count: bike.availableCount,
                        }),
                      }}
                    />
                  </div>
                  )}
                  {bike.soldout && (
                  <div className={`${classes.bcSoldOut} ${classes.bcLabel}`}>
                    <ErrorOutlineIcon />
                    <span>{t('fe_bike_card:soldout')}</span>
                  </div>
                  )}
                  {bike.tentative && !bike.availableCount && (
                  <div className={`${classes.bcTentative} ${classes.bcLabel}`}>
                    <ErrorOutlineIcon />
                    <span>{`${t('fe_bike_card:limited_availability')} !`}</span>
                  </div>
                  )}
                  {!bike.availableCount && !bike.soldout && !bike.tentative && bike.valueDeal && (
                  <div className={`${classes.bcValueDeal} ${classes.bcLabel}`}>
                    <CardGiftcardIcon />
                    <span>{t('fe_bike_card:value_deal')}</span>
                  </div>
                  )}
                </div>
                <Grid className={`${classes.cardContent}`}>
                  <Grid item className={`${classes.gridItem} ${classes.cardHeader}`}>
                    <div className={classes.bcTitle}>
                      <p className={`${classes.make} js-make-name`}>{bike.vehicle_make.name}</p>
                      <p className={`${classes.model} js-model-name`}>{bike.vehicle_model.name}</p>
                    </div>

                    <Box className={classes.nPrices}>
                      <Box className={classes.nDailyPrice}>
                        <span className="amount">{bike.price || bike.hourly_price}</span>
                        <span>
                          /
                          {bike.price ? t('fe_bike_card:day') : t('fe_bike_card:hour')}
                        </span>
                      </Box>
                      {bike.price && bike.hourly_price && (
                        <Box className={classes.nHourlyPrice}>
                          <span className="amount">{bike.hourly_price}</span>
                          <span>
                            /
                            {t('fe_bike_card:hour')}
                          </span>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item className={`${classes.gridItem} ${classes.bcLocationCol}`}>
                    <div className={`${classes.bcLocation} ${classes.bcShared}`}>
                      <LocationOnIcon />
                      {' '}
                      <span>{bike.region.name}</span>
                    </div>
                    {bike
                      && (
                        <div className={`${classes.bcOwner} ${classes.bcShared}`}>
                          {renter.icon}
                          <span>
                            {t(renter.translationKey)}
                            <b className={classes.bcOwnerName}>
                              {renter.name}
                            </b>
                          </span>
                        </div>
                      ) }
                  </Grid>
                  <Box>
                    {bike.specs
                && (
                <Grid item className={`${classes.gridItem} ${classes.bcMeasuresCol}`}>
                  {bike.specs.height && (
                  <div className={`${classes.bcHeight} ${classes.bcShared}`}>
                    <HeightIcon />
                    <span>
                      <b>{bike.specs.height}</b>
                      <span className={classes.unit}>{t('fe_bike_card:seat_height')}</span>
                    </span>
                  </div>
                  )}
                  {bike.specs.weight && (
                  <div className={`${classes.bcWeight} ${classes.bcShared}`}>
                    <WeightIcon />
                    <span>
                      <b>{bike.specs.weight}</b>
                      {/* <span className='unit'>{i18n.weight}</span> */}
                    </span>
                  </div>
                  )}
                  {bike.specs.cubic_centemeter && (
                  <div className={`${classes.bcCC} ${classes.bcShared}`}>
                    <CCIcon />
                    <span>
                      <b>{bike.specs.cubic_centemeter}</b>
                      {/* <span className='unit'>{i18n.cc}</span> */}
                    </span>
                  </div>
                  )}
                </Grid>
                )}
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </ButtonBase>
        )
        : (
          <div className={classes.cardSkeletonWrap}>
            <Skeleton variant="rect" height={190} />
            <Box p={2} className="footer">
              <Skeleton width="30%" />
              <Skeleton width="50%" />
              <br />
              <Skeleton />
              <Skeleton />
            </Box>
          </div>
        )}
    </>
  );
};

export default withTranslation(['fe_bike_card'])(BikeCard);
